import { Link } from "gatsby"
import React from "react"
import ConditionalWrapper from "../../components/conditional-wrapper"
import Page from "../../components/page"
import ResponsiveImage from "../../components/responsive-image"
import { themes } from "../../styles/themes"
import paths from "../../utils/paths"
import useScreenSize from "../../utils/useScreenSize"

const pressItems = (labels, pagePaths, isSmallScreen) => [
  {
    src: "/images/press/klub-7-lifestyle-magazin-igor-prodanovic.jpg",
    title: labels.pressKlub7LifestyleMagazinTitle,
    alt: labels.pressKlub7LifestyleMagazinAlt,
    href: "https://youtu.be/u9lV2c0jidw"
  },
  {
    src: "/images/press/55-zagrebacki-salon.jpg",
    title: labels.press55ZGSalonTitle,
    alt: labels.press55ZGSalonAlt,
    href: pagePaths.petPetZgSalon,
    target: "_self"
  },
  {
    src:
      "/images/press/jutarnji-list-dom-i-dizajn-mastovit-strastven-drugaciji-namjestaj.jpg",
    title: labels.pressJutarnjiListDomIDizajnTitle,
    alt: labels.pressJutarnjiListDomIDizajnAlt,
    href:
      "https://www.jutarnji.hr/domidizajn/inspiracije/arhitekt-i-dizajner-igor-prodanovic-pokrenuo-je-brend-koji-sjedinjuje-dozivljaj-razlicitih-umjetnosti-10357900"
  },
  {
    src: "/images/press/vecernji-list-zivotna-prica.jpg",
    title: labels.pressVecernjiListTitle,
    alt: labels.pressVecernjiListAlt,
    href:
      "https://www.vecernji.hr/vijesti/inspiriraju-me-uzitci-renesanse-i-baroka-uzori-su-mi-dali-i-bauhaus-no-seksi-namjestaja-ne-bi-bilo-bez-gospona-fulira-1422030"
  },
  {
    src: isSmallScreen
      ? "/images/press/vecernji-list-zagrebacki-salon-svi-bi-htjeli-sjesti-na-seksi-kauc.jpg"
      : "/images/press/vecernji-list-zagrebacki-salon-svi-bi-htjeli-sjesti-na-seksi-kauc-square.jpg",
    title: labels.pressVecernjiList55ZGSalonTitle,
    subtitle: labels.pressVecernjiList55ZGSalonSubtitle,
    alt: labels.pressVecernjiList55ZGSalonAlt,
    href:
      "https://www.vecernji.hr/zagreb/svi-bi-htjeli-sjesti-na-seksi-kauc-i-fotelju-od-rola-wc-papira-1415730"
  },
  {
    src:
      "/images/press/t-portal-hr-puno-toga-nam-je-seksi-zasto-to-onda-ne-bi-bio-namjestaj-prostor.jpg",
    title: labels.pressTPortalTitle,
    subtitle: isSmallScreen ? undefined : labels.pressTPortalSubtitle,
    alt: labels.pressTPortalAlt,
    href:
      "https://www.tportal.hr/biznis/clanak/foto-video-arhitekt-i-menadzer-igor-prodanovic-odlucio-se-za-veliki-karijerni-zaokret-i-pokrenuo-brend-sexyinterijeri-puno-toga-nam-je-seksi-zasto-to-onda-ne-bi-bio-i-namjestaj-ili-prostor-foto-20200927"
  },
  {
    src:
      "/images/press/dom-i-dizajn-simbol-slobode-i-nesputanosti-easy-rider.jpg",
    title: labels.pressDomIDizajnTitle,
    alt: labels.pressDomIDizajnAlt,
    href:
      "https://www.jutarnji.hr/domidizajn/inspiracije/simbol-slobode-i-nesputanosti-stolac-easy-chair-rider-arhitekta-i-dizajnera-igora-prodanovica-15086562"
  },
  {
    src: "/images/press/extravagant-interijeri-sexyinterijeri.jpg",
    title: labels.pressExtravagantTitle,
    alt: labels.pressExtravagantAlt,
    href: "https://extravagant.com.hr/extravagant-interijeri-sexyinterijeri"
  },
  {
    src:
      "/images/press/ribafish-sexyinterijeri-namjestaj-po-mjeri-pravih-hedonista.jpg",
    title: labels.pressRibafishTitle,
    subtitle: isSmallScreen ? undefined : labels.pressRibafishSubtitle,
    alt: labels.pressRibafishAlt,
    href:
      "https://ribafish.com/sexyinterijeri-namjestaj-po-mjeri-pravih-hedonista"
  },
  {
    src: "/images/press/gradimo-hr.jpg",
    title: labels.pressGradimoHrTitle,
    subtitle: labels.pressGradimoHrSubtitle,
    alt: labels.pressGradimoHrAlt
  }
]

export default function Press({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsWhite(false)}
      title={labels.press}
      description={labels.pressDescription}
      keywords={labels.pressKeywords}
      ogUrl={paths(labels).press}
      ogImage="/images/og-images/press.jpg"
      headerPadding
      footerPadding
    >
      <div className="press-grid">
        {pressItems(labels, paths(labels), isSmallScreen).map(item => (
          <div className="press-item-container">
            <ConditionalWrapper
              condition={item.href}
              wrapper={children =>
                item.target ? (
                  <Link to={item.href} target={item.target}>
                    {children}
                  </Link>
                ) : (
                  <a href={item.href} target="_blank" rel="noreferrer">
                    {children}
                  </a>
                )
              }
            >
              <div className="press-item-title-container">
                <p className="press-item-title">{item.title}</p>
                {item.subtitle && (
                  <p className="press-item-subtitle">{item.subtitle}</p>
                )}
              </div>
              <ResponsiveImage
                containerClass="img-container press-item-img-container"
                src={item.src}
                imgTitle={item.title}
                alt={item.alt}
                fit="contain"
              />
            </ConditionalWrapper>
          </div>
        ))}
      </div>
    </Page>
  )
}
